import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../partial/TextValidator";
import PasswordValidator from "../partial/PasswordValidator";
import { Internal_Token, signUp, Token_Generator } from "../../services/auth";
import Loader from "../partial/Loader";
import { alertModal } from "../../utils/helper";


const SignUp = () => {
    const formRef = useRef();
    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        emailID: "",
        contactNumber: "",
        newPassword: "",
        referralCode: "",
        tAndC: false,
        error: false,
    });
    const [isReferralCode, setIsReferralCode] = useState(false);
    const [finalData, setFinalData] = useState({});
    const [loader, setLoader] = useState(false);
    const invalidDomains = ["mailinator", "cwmxc", "horsgit", "maildrop", "explodemail", "stresspc", "anonaddy"];

    const { name, emailID, contactNumber, newPassword, tAndC, error, referralCode } = formData;
    const onChange = (e) => setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });

    useEffect(() => {
        const validateEmail = (email) => {
            const domainPattern = invalidDomains.join("|");
            const regexPattern = `^(?!.*@(${domainPattern})\\b)([a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`;

            const regex = new RegExp(regexPattern);
            if (email) {
                if (regex.test(email)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        };
        ValidatorForm.addValidationRule("validEmail", (value) => {
            if (validateEmail(value)) {
                return true;
            }
            return false;
        });
        // eslint-disable-next-line
    }, [emailID]);

    useEffect(() => {
        const validateName = (name) => {
            return /^[A-Za-z\s]*$/.test(name);
        };
        ValidatorForm.addValidationRule("validName", (value) => {
            if (validateName(value)) {
                return true;
            }
            return false;
        });
    }, [name]);

    useEffect(() => {
        let data = [];
        data.push({ name: name, mobile: contactNumber, email: emailID, password: newPassword, referralcode: isReferralCode ? referralCode : "" });
        setFinalData(data[0]);
    }, [formData, isReferralCode]); // eslint-disable-line

    const handleCheckbox = (e) => setFormData({ ...formData, tAndC: e.target.checked });

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!tAndC) {
            setFormData({ ...formData, error: true });
        } else {
            setLoader(true);
            let tokenBody = {
                clientid: "maritime_owner638495592039727738",
                grant_type: "client_credentials",
                clientsecret: "LYLRUJVORC",
            };
            const token = await Token_Generator();
            const response = await signUp(token?.access_token, finalData);
            if (response.message.message === "Request processed successfully.") {
                setLoader(false);
                alertModal("center", "success", "Customer Registered Successfully.", "button", "ok", 5000);
                navigate("/signin");
            } else if (response.message.message === "Customer already exists") {
                alertModal("center", "warning", "Customer already exists", "button", "oh no!", 5000);
                setLoader(false);
            } else {
                alertModal("center", "error", response.message.message, "button", "OK", 5000);
                setLoader(false);
            }
            setLoader(false);
        }
    };

    const handleLinkClick = (e) => {
        e.preventDefault();
        window.open(e.target.href, "_blank");
    };

    return (
        <section className="section signUpPage">
            {loader && <Loader />}
            <div className="signUpPage__main">
                <div className="signUpPage__right">
                    <div className="signUpPage__heading">
                        <figure>
                        <img src={"/Images/maritime_main_logo.png"} alt="logo" />
                        </figure>
                        <h1>Great to see you here!</h1>
                    </div>
                    <div className="signUpPage__form">
                        <ValidatorForm className="signUpPage__formSection" ref={formRef} autoComplete="off" autoCapitalize="off" onSubmit={(e) => onSubmit(e)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextValidator className="form-control" placeholder="Name" name="name" maxLength="50" value={name} onChange={onChange} validators={["required", "trim", "validName"]} errorMessages={["Please enter your name", "Enter valid Name", "Enter valid Name"]} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextValidator className="form-control" placeholder="Email" name="emailID" maxLength="50" value={emailID} type="email" onChange={onChange} validators={["required", "validEmail"]} errorMessages={["Please enter your email", "Enter valid email"]} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextValidator className="form-control" placeholder="Contact Number" name="contactNumber" maxLength="50" value={contactNumber} onChange={onChange} validators={["required", "matchRegexp:^(?!\\d*(\\d)\\1{7,}|0123456789|1234567890)\\d{10,15}$"]} errorMessages={["Please enter your contact number", "Please enter a valid number"]} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group withIcon">
                                        <span className="passwordToggle">{passwordFocus ? <img className="onFocus" title={isRevealPassword ? "Hide password" : "Show password"} src={isRevealPassword ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setIsRevealPassword((prevState) => !prevState)} /> : <img className="onBlur" title={isRevealPassword ? "Hide password" : "Show password"} src={isRevealPassword ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setIsRevealPassword((prevState) => !prevState)} />}</span>
                                        <PasswordValidator placeholder="New Password" className="form-control" name="newPassword" minLength="6" maxLength="16" value={newPassword} type={isRevealPassword ? "text" : "password"} onChange={onChange} validators={["required", "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,16}$"]} errorMessages={["Please enter password.", "condition"]} onFocus={() => setPasswordFocus(true)} onBlur={() => setPasswordFocus(false)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group withIcon">
                                        <button className="btn btn-primary" type="button" onClick={() => setIsReferralCode(!isReferralCode)}>
                                            Have a Referral Code?
                                        </button>
                                    </div>
                                </div>
                                {isReferralCode && (
                                    <div className="col-md-6">
                                        <div className="form-group withIcon">
                                            <TextValidator className="form-control" placeholder="Enter Referral Code" name="referralCode" maxLength="50" value={referralCode} onChange={onChange} validators={["required"]} errorMessages={["Please enter referral code"]} />
                                        </div>
                                    </div>
                                )}
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="signup_terms" className="signup_checklabel">
                                            <input type="checkbox" onChange={handleCheckbox} id="signup_terms" />
                                            <Link to="/terms-and-conditions" onClick={handleLinkClick}>
                                                I agree to terms & conditions
                                            </Link>
                                        </label>
                                        {error && !tAndC && <span className="textValidatorError">Please agree to T&C</span>}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-lg w-100">
                                            Submit
                                        </button>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <p>
                                            Already have an account? <Link to={`/signin`}>Click here to login</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ValidatorForm>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SignUp;