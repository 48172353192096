import { getNewAuthToken, isTokenExpired } from "../utils";
import { baseURL } from "../utils/url";

export const CountryRegions = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "CountryRegions", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
export const orderHistory = async (code, client) => {
    try {
        const fetchResponse = await fetch(`${baseURL}orderHistory?code=${code}&client=${client}`, {
            method: "GET",
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const orderPlaced = async (body) => {
    try {
        const fetchResponse = await fetch(`${baseURL}orderPlaced`, {
            method: "POST",
            headers: {
                Authorization: "Bearer ",
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const refund = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "refund", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getCustomerGiftcard = async (code) => {
    try {
        const fetchResponse = await fetch(`${baseURL}getCustomerGiftcard?code=${code}`, {
            method: "GET",
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
export const getCampaigns = async (token, id) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getCampaigns?id=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};
export const voucherTemplateDetails = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getVoucherTemplateByMerchant", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const customerView = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + `CustomerProfileData?customerCode=${data}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
            id: data,
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getVoucherTemplateStatusByCode = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getStatusRedeemVoucher", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const transactionRedeem = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "transactionredeem", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: data,
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const receiptFilePath = async (body) => {
    try {
        const fetchResponse = await fetch(`${baseURL}receiptFilePath`, {
            method: "POST",
            headers: {
                Authorization: "Bearer ",
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse;
    } catch (error) {
        return error;
    }
};

export const fileUpload = async (token, file) => {
    const formData = new FormData();
    formData.append("file", file);
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + `fileUpload`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
            body: formData,
        });
        return fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getMerchantAndLogo = async (token, id) => {
    console.log(id,"id")
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getMerchantNameAndLogo?id=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const transaction = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "transaction", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const merchantSchema = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + `merchantSchema?id=${data}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
            id: data,
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getBanner = async (token, id) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getBanner?id=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getTheme = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getTheme", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const voucherPurchase = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "voucherPurchase", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const getCustomer = async (token, offset, limit, code) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + `getCustomer?offset=${offset}&limit=${limit}&code=${code}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getTransaction = async (token, code, id) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + `getTransaction?code=${code}&id=${id}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getGeneratedVoucher = async (token, customerDetail) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + `getGeneratedVoucher?customerDetail=${customerDetail}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const ssoLogin = async (token, access_key) => {
    try {
        const fetchResponse = await fetch(baseURL + `ssoLogin?access_key=${access_key}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getCustomerData = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getCustomerData", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
