import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

//auth
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import ForgotPswd from "./components/auth/ForgotPswd";
import ResetPswd from "./components/auth/ResetPswd";
//routes
import ProtectedRoute from "./routes/ProtectedRoute";
import PublicRoute from "./routes/PublicRoute";
//pages
import CustomerPortal from "./components/pages/CustomerPortal";
import NotFound from "./components/pages/NotFound";
import Header from "./components/layout/Header";
import ChooseRewardLive from "./components/pages/ChooseRewardLive";

const App = () => {
    const HeaderLayout = () => (
        <Header>
            <Outlet />
        </Header>
    );

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={<HeaderLayout />}>
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute>
                                    <CustomerPortal />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/customer-portal"
                            element={
                                <ProtectedRoute>
                                    <CustomerPortal />
                                </ProtectedRoute>
                            }
                        />

<Route
                            path="/chooseReward"
                            element={
                                <ProtectedRoute>
                                    <ChooseRewardLive />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path="*"
                            element={
                                <ProtectedRoute>
                                    <NotFound />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="/"
                        element={
                            <PublicRoute>
                                <SignIn />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/signin"
                        element={
                            <PublicRoute>
                                <SignIn />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/auth/sso-login"
                        element={
                            <PublicRoute>
                                <SignIn />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/signup"
                        element={
                            <PublicRoute>
                                <SignUp />
                            </PublicRoute>
                        }
                    />

                    <Route
                        path="/forgot-password"
                        element={
                            <PublicRoute>
                                <ForgotPswd />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/reset-password"
                        element={
                            <PublicRoute>
                                <ResetPswd />
                            </PublicRoute>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;