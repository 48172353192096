import moment from "moment";
import swal from "sweetalert";

export const getDate = (date, format) => {
    if (date && date !== "N/A") {
        try {
            let newDate = new Date(date).toISOString();
            let forMattedDate = moment(newDate).format(format);
            if (forMattedDate !== "Invalid date") {
                return forMattedDate;
            } else {
                return "";
            }
        } catch (e) {}
    }
};

export const alertModal = (position, icon, title, button, buttonText, timer, text) => {
    return swal({
        position,
        icon,
        title,
        [button]: buttonText,
        timer,
        text,
    });
};

export const validEmail = (mail) => {
    //eslint-disable-next-line
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!mail) return false;
    return mail.match(mailformat);
};
