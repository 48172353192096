import React from "react";
import { notFoundImg } from "../../Image/Index";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="dashboard__content">
            <div className="container-fluid">
                <div className="dashboard__notFoundBox">
                    <div className="dashboard__notFoundBox__inside">
                        <figure className="dashboard__notFoundBox__fig">
                            <img src={notFoundImg} alt="" />
                        </figure>
                        <h3 className="dashboard__notFoundBox__title">Oops. That page doesn't exist.</h3>
                        <Link className="dashboard__notFoundBox__button" to="/customer-portal">
                            Go To Portal
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
