import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../partial/TextValidator";
import { getCustomerData, ssoLogin } from "../../services/services";
import { Internal_Token, LogIn, signIn, TestData, Token_Generator } from "../../services/auth";
import Loader from "../partial/Loader";

function SignIn() {
    const location = useLocation();
    const formRef = useRef();
    const navigate = useNavigate();
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [pswdFocus, setPswdFocus] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [searchParams] = useSearchParams();
    const { email, password } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    };


    useEffect(() => {
        const ssoAuthLogin = async () => {
            setLoader(true);
            const access_key = searchParams.get("access_key");
            const code = "MER001149";
            let tokenBody = {
                clientid: "maritime_owner638495592039727738",
                grant_type: "client_credentials",
                clientsecret: "LYLRUJVORC",
            };
         
            // http://localhost:3000/auth/sso-login?code=MER001149&access_key=8163FBDNE17205920843123538
            const internalToken = await Token_Generator();
            const ssoRes = await ssoLogin(internalToken.access_token, access_key);
            if (ssoRes.message === "Request processed successfully.") {
                const getName = await getCustomerData(internalToken.access_token, { customercode: ssoRes.data.customercode });
                if (getName.message === "Request processed successfully.") {
                    localStorage.setItem("firstName", getName.data.firstname);
                    localStorage.setItem("Id", code);
                    localStorage.setItem("name", getName.data.name);
                    localStorage.setItem("email", getName?.data?.email);
                    localStorage.setItem("surname", getName?.data?.surname);
                    localStorage.setItem("token_gen", JSON.stringify(internalToken));
                    localStorage.setItem("c_code", ssoRes.data.customercode);
                    localStorage.setItem("clientsecret", "LYLRUJVORC");
                    localStorage.setItem("clientid", "maritime_owner638495592039727738");
                    navigate("/customer-portal");
                } else {
                    setError(getName.message);
                }
            } else {
                setError(ssoRes?.message);
            }

            setLoader(false);
        };

        // demo@maritimefinancial.com

        if (location?.pathname.includes("sso-login")) {
            ssoAuthLogin();
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = async () => {
        const body = {
            email: email,
            password: password,
        };
        setLoader(true);
        let tokenBody = {
            client_id:"maritime_owner638495592039727738",
            client_secret:"LYLRUJVORC",
            grant_type:"client_credentials"
        };
        const token = await Token_Generator();
        const res = await LogIn(token?.access_token, body);
        if (res?.data?.message === "success") {
            localStorage.setItem("token_gen", JSON.stringify(token));
            localStorage.setItem("c_code", res?.data?.data?.customercode);
            localStorage.setItem("name", res?.data?.data?.name);
            localStorage.setItem("email", res?.data?.data?.email);
            localStorage.setItem("firstName", res?.data?.data?.firstname);
            localStorage.setItem("surname", res?.data?.data?.surname);
            localStorage.setItem("Id", "MER001149");
            localStorage.setItem("clientsecret","LYLRUJVORC");
            localStorage.setItem("clientid","maritime_owner638495592039727738");
            navigate("/customer-portal");
        } else {
            setError(res?.message);
        }
        setLoader(false);
    };

    return (
        <section className="section signUpPage">
            {loader && <Loader />}
            <div className="signUpPage__main">
                <div className="signUpPage__right">
                    <div className="signUpPage__heading">
                        <figure>
                        <img src={"/Images/maritime_main_logo.png"} alt="Hero Banner" />
                        </figure>
                        <h1>Great to have you back!</h1>
                    </div>
                    <div className="signUpPage__form">
                        <ValidatorForm className="signUpPage__formSection" ref={formRef} onSubmit={(e) => onSubmit(e)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <TextValidator className="form-control" placeholder="Enter Email ID" name="email" maxLength="50" value={email} type="email" onChange={onChange} validators={["required"]} errorMessages={["Email address is required."]} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group withIcon">
                                        <TextValidator placeholder="Enter Password" className="form-control" name="password" value={password} type={isRevealPwd ? "text" : "password"} onChange={onChange} validators={["required"]} errorMessages={["Password is required."]} onFocus={() => setPswdFocus(true)} onBlur={() => setPswdFocus(false)} />
                                        <span className="passwordToggle">{pswdFocus ? <img className="onFocus" title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setIsRevealPwd((prevState) => !prevState)} /> : <img className="onBlur" title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setIsRevealPwd((prevState) => !prevState)} />}</span>
                                        {error && <span className="textValidatorError">{error}</span>}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <p className="text-end">
                                            <Link to="/forgot-password">Forgot Password ?</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-lg w-100">
                                            Sign in
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>
                                            New here? <Link to="/signup">Create an account now</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ValidatorForm>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default SignIn;