import React from "react";
import { Navigate } from "react-router-dom";
import LogoutTimer from "./LogoutTimer";
import RefreshInternalToken from "./RefreshInternalToken";

const ProtectedRoute = ({ children }) => {
    const temp = JSON.parse(localStorage.getItem("token_gen"));

    if (!temp?.access_token || temp?.access_token === null || undefined) {
        return <Navigate to={`/signin`} />;
    }
    return (
        <>
            {children}
            <LogoutTimer />
            <RefreshInternalToken />
        </>
    );
};

export default ProtectedRoute;
