import React, { useState, useEffect } from "react";
import { orderHistory, customerView, getGeneratedVoucher } from "../../services/services";
import UploadModal from "./UploadModal";
import RedemptionModal from "./RedemptionModal";
import { Link, useNavigate } from "react-router-dom";
// import Loader from "../partial/Loader";
import { getDate } from "../../utils/helper";
import { useLocation } from "react-router-dom";
import { giftCard, website_banner } from "../../Image/Index";
import moment from "moment";
import Page from "./Page";

import ClipLoader from 'react-spinners/ClipLoader';
import Loader from "../partial/Loader";

const CustomerPortal = () => {
    const location = useLocation();
    const [orderList, setOrderList] = useState([]);
    const navigate = useNavigate();
    const [rewardData, setRewardData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loader, setLoader] = useState(true);

    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        campaigns: [],
        activityLogs: [],
        referralCode: "",
        rewards: [],
        uploadModal: false,
        redemptionModal: false,
        flag: true,
        choose_rewards_flag: true,
        email: "",
        banner: [],
        displayUploadReceipt: false,
        displayStoreRedemption: false,
        template: [],
        pointImg: false,
        walletSummary: [],
        walletsDetail: {},
        socialMedia: [],
        transactions: [],
    });

    const [redeemReward, setRedeemReward] = useState(false);
    const [matchedVoucherStatus, setMatchedVoucherStatus] = useState([]);

    const pageSize = 10;
    const pageCount = Math.ceil(matchedVoucherStatus?.length / pageSize);

    const [buttons, setButtons] = useState({
        home: true,
        choose_rewards: false,
        inStore: false,
    });
    const [homeState, setHomeState] = useState({
        showModal: false,
        history: [],
        enableCamp: [],
        linkUrl: [
            {
                camp: [],
                url: "",
            },
        ],
        disableCamp: [],
    });

    const [toggle, setToggle] = useState({
        isActive: false,
        index: null,
    });

    const { isActive, index } = toggle;

    const toggleActive = (ind) => {
        if (index === ind) {
            setToggle({ ...toggle, index: ind, isActive: !isActive });
        } else {
            setToggle({ ...toggle, index: ind, isActive: true });
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get('tab');
        if (tab) {
            setActiveTab(tab);
        }
    }, [location.search]);


    const [share, setShare] = useState({
        shareUrl: "",
        copied: false,
    });
    const { showModal, history, enableCamp, disableCamp, linkUrl } = homeState;

    const { referralCode, uploadModal, redemptionModal, campaigns, email, banner, displayUploadReceipt, displayStoreRedemption, pointImg, walletsDetail, socialMedia, rewards, activityLogs } = state;
    const { home, choose_rewards, inStore } = buttons;
    const { shareUrl, copied } = share;

    const [activeTab, setActiveTab] = useState("overview");

    useEffect(() => {
        setShare((prev) => ({
            ...prev,
            code: referralCode,
            shareUrl: referralCode,
        }));
    }, [referralCode]);

    useEffect(() => {
        if (location?.state?.home) {
            setButtons((prev) => ({
                ...prev,
                home: location?.state?.home,
                choose_rewards: location?.state?.choose_rewards,
                inStore: false,
            }));
        }
    }, [location]);

    useEffect(() => {
        const sortedLogs = activityLogs
            ?.filter((ele) => ele.transactionType && ele)
            ?.sort((a, b) => new Date(b.transctionDate) - new Date(a.transctionDate));

        setHomeState((prev) => ({ ...prev, history: sortedLogs }));
    }, [activityLogs]);


    useEffect(() => {
        const fetch = async () => {
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const customerCode = localStorage.getItem("c_code");
            const id = localStorage.getItem("Id");
            let profileData = customerView(token.access_token, customerCode);
            let camp = [];

            const body = {
                customercode: customerCode,
            };


            Promise.all([profileData, camp,]).then((values) => {
                profileData = values[0];
                camp = values[1];


                setState((prevState) => ({
                    ...prevState,
                    campaigns: camp,
                    logo: localStorage.getItem("logo"),
                    uploadedSource: localStorage.getItem("uploadedSource"),
                    activityLogs: profileData?.activityLogs,
                    walletSummary: profileData?.WalletSummary?.filter((wallet) => getDate(wallet?.redeemableAfterDate, "YYYY-MM-DD") > getDate(new Date(), "YYYY-MM-DD") && wallet),
                    walletsDetail: profileData?.walletsDetail,
                    referralCode: profileData?.customer?.referralcode,
                    flag: false,
                    rewards: rewards,
                    email: profileData?.customer?.email,

                    displayUploadReceipt: banner[0]?.isReciptUpload,
                    displayStoreRedemption: banner[0]?.inStoreRedeption,
                    pointImg: banner[0]?.isPointImg,
                    socialMedia: banner[0]?.socialMedia,

                }));
                setLoader(false);
            });
        };
        fetch();
    }, []);

    useEffect(() => {
        const fetchOrderHistory = async () => {
            if (activeTab === "giftcard") {
                setLoading(true)
                const customerCode = localStorage.getItem("c_code");
                await orderHistory(customerCode, "34fg54-6f44-4bd9-maritime-35646g-gnkjfd54kc-56hgt9").then((res) => {
                    setOrderList(res.data);
                    setLoading(false)
                });
            }
        };
        fetchOrderHistory();
    }, [activeTab]);




    useEffect(() => {
        const fetchVoucherHistory = async () => {
            if (activeTab === "voucher") {
                setLoading(true)
                const token = JSON.parse(localStorage.getItem("token_gen"));
                const customerCode = localStorage.getItem("c_code");
                await getGeneratedVoucher(token?.access_token, customerCode).then((res) => {
                    setMatchedVoucherStatus(res?.data?.data.customerData.voucherData);
                    setLoading(false)
                });
            }
        };
        fetchVoucherHistory();
    }, [activeTab]);

    const handleCopy = () => {
        setShare((prev) => ({ ...prev, copied: true }));
        setTimeout(() => {
            setShare((prev) => ({ ...prev, copied: false }));
        }, 3000);
    };

    return (
        <>
            {loader && <Loader />}
            <div className="customer_portal__mainPage">
                <div className="hero-banner">
                    <img src={website_banner} alt="Hero Banner" />
                </div>
                <>
                    <div className="new_header_main" id="new_header_main">
                        <div className="header_menu_box">
                            <div className="header_points_box">
                                <h4>Your Points <img src={'/Images/star-silver.png'} /> <span>{walletsDetail?.availableValue}</span></h4>
                            </div>
                            <div className="choose_rewards_box">
                                <Link to='/chooseReward' state={state}>
                                    Redeem Rewards
                                </Link>
                            </div>
                        </div>
                        <div className="header_menu_list">
                            <ul className="nav nav-tabs" id="tabs-nav" role="tablist">
                                <li className="nav-item active" role="presentation">
                                    <a href="#" className={`nav-link ${activeTab === "overview" ? "active" : ""}`} id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" role="tab" aria-controls="home" aria-selected={activeTab === "overview"} onClick={() => setActiveTab("overview")}>Overview</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className={`nav-link ${activeTab === "history" ? "active" : ""}`} id="history-tab" data-bs-toggle="tab" data-bs-target="#history" role="tab" aria-controls="profile" aria-selected={activeTab === "history"} onClick={() => setActiveTab("history")}>History</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className={`nav-link ${activeTab === "voucher" ? "active" : ""}`} id="voucher-tab" data-bs-toggle="tab" data-bs-target="#voucher" role="tab" aria-controls="contact" aria-selected={activeTab === "voucher"} onClick={() => setActiveTab("voucher")}>Vouchers</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className={`nav-link ${activeTab === "giftcard" ? "active" : ""}`} id="giftcard-tab" data-bs-toggle="tab" data-bs-target="#giftcard" role="tab" aria-controls="contact" aria-selected={activeTab === "giftcard"} onClick={() => setActiveTab("giftcard")}>Gift Cards</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="customer_tab_content">
                        
                    {loading ? (<div className="loader-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding:"10px"}}>
                                <ClipLoader
                                    color={'#D000218'}
                                    loading={loading}

                                    size={50}

                                />
                            </div>
                            ) : (
                        <div className="tab-content" id="tabs-content">
                            <div className={`tab-pane ${activeTab === "overview" ? "active" : ""}`} id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                <div className="section_1_box">
                                    <div className="inner_section_1">
                                        <h3>Earn Maritime Rewards</h3>
                                        <p>Your loyalty with Maritime earns points automatically. We'll track your business with us and grant you points to redeem amazing rewards!</p>
                                        <div className="rewards_main_box">
                                            <div className="rewards_desbox">
                                                <img src={"/Images/star_white.png"} />
                                                <h5>Earn Points with every transaction</h5>
                                                <p>Gain points effortlessly when you pay your premiums</p>
                                            </div>
                                            <div className="rewards_desbox">
                                                <img src={"/Images/diamond.png"} />
                                                <h5>Climb Tiers for Bonus Points</h5>
                                                <p>Unlock greater rewards when you have multiple business with Maritime</p>
                                            </div>
                                            <div className="rewards_desbox">
                                                <img src={"/Images/box.png"} />
                                                <h5>Redeem Internally for Bonus Points</h5>
                                                <p>Enjoy exclusive discounts on your business with us</p>
                                            </div>
                                            <div className="rewards_desbox">
                                                <img src={"/Images/red-flag.png"} />
                                                <h5>Earn + Redeem Globally</h5>
                                                <p>Access our diverse selection of international and local rewards</p>
                                            </div>
                                            <div className="rewards_desbox">
                                                <img src={"/Images/exclusive.png"} />
                                                <h5>Exclusive Offers and Promotions</h5>
                                                <p>Get exclusive promotions and offers tailored for our Valued Clients</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="section_strip">
                                    <h2>EARN AND REDEEM VOUCHERS ACROSS THE MARITIME FINANCIAL GROUP</h2>
                                </div>

                                <div className="section_2_box">
                                    <div className="inner_section_2">
                                        <h3>Redeem Maritime Rewards</h3>
                                        <p>Enjoy special offers and discounts at any one of our discount partners, internationally and locally</p>
                                        <div className="rewards_main_box_2">
                                            <div className="rewards_desbox_2">
                                                <img src={"/Images/maritime_giftcard.png"} />
                                                <h5>International Gift Card</h5>
                                                <ul>
                                                    <li><strong>Global Brands at Your Fingertips:</strong> Travel through a diverse range of international gift card choices</li>
                                                    <li><strong>Worldwide Shopping Spree:</strong> Choose from a plethora of gift cards for your shopping adventure</li>
                                                    <li><strong>Unlimited Choices, Endless Possibilities:</strong> Open doors to unique and exciting purchases worldwide </li>
                                                </ul>
                                            </div>
                                            <div className="rewards_desbox_2">
                                                <img src={"Images/maritime_giftcard.png"} />
                                                <h5>Local Gift Card and Voucher</h5>
                                                <ul>
                                                    <li><strong>Shop and Save with Maritime:</strong> Get special offers and discounts across Maritime, including Food Giant Supermarket!</li>
                                                    <li><strong>Exclusive Savings, Local Flavour:</strong> Enjoy discounts from your favourite local spots</li>
                                                    <li><strong>Discover Local Gems:</strong> Uncover new favourites and support local treasures with great discounts</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane ${activeTab === "history" ? "active" : ""}`} id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="tab_boxx">
                                    <div className="history__completed">
                                        <div className="history__completedList">
                                            {history?.length ? (
                                                <ul className="list_all">
                                                    {history?.map((ele, i) => (
                                                        <li key={i}>
                                                            <div className="transaction_box">
                                                                <div className="transaction_details ">
                                                                    <div className="history_details">
                                                                        <h5 style={{ fontSize: "0.8rem", fontWeight: "700" }}>Trans ID: {ele.transactionId}</h5>
                                                                    </div>
                                                                    <div className="transaction_status">
                                                                        <span className="transaction_time">{moment(ele.transctionDate).format('YYYY-MM-DD HH:mm:ss a')}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="history__points">
                                                                    <div className="transaction_points">
                                                                        <span className="tp_value refunded">
                                                                            {ele.transactionType === "redeem" ? "Point Redeem" : ele.transactionType === "purchase" ? "Earned Value" : ele.transactionType === "Refund" ? "Point Refunded against purchase" : ele.transactionType === "VoucherPurchase" ? "Voucher purchased" : ele.transactionType === "VoucherRedeem " ? "Voucher redeem" : ele.transactionType === "purchase " ? "Point Earned" : ele.transactionType === "Settled " ? "Transaction Settled" : ele.action}
                                                                        </span>
                                                                        {ele.transactionType !== "VoucherPurchase" && (
                                                                            ele.transactionType === "redeem" ? (
                                                                                <span className="redeemed">-{ele.value} {ele.valueType}</span>
                                                                            ) : (
                                                                                <span className="refunded">+{ele.value} {ele.valueType}</span>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <h6 className="text-center"> No Record Found</h6>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <div className={`tab-pane ${activeTab === "voucher" ? "active" : ""}`} id="voucher" role="tabpanel" aria-labelledby="voucher-tab">
                                    <div className="table-responsive" style={{ margin: "10px" }}>
                                        <table className="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Voucher Name</th>
                                                    <th>Voucher Code</th>
                                                    <th>Value</th>
                                                    <th>Purchase Date</th>
                                                    <th>Expire Date</th>
                                                    <th>IsReedemed</th>
                                                </tr>
                                            </thead>
                                            {
                                                <tbody>

                                                    {matchedVoucherStatus && matchedVoucherStatus.length > 0
                                                        ? matchedVoucherStatus.sort(function (a, b) {
                                                            if (a.createdDate === null) {
                                                                return 1;
                                                            } else if (b.createdDate === null) {
                                                                return -1;
                                                            }
                                                            const dateA = new Date(a.createdDate);
                                                            const dateB = new Date(b.createdDate);
                                                            if (dateA < dateB) {
                                                                return 1;
                                                            } else if (dateA > dateB) {
                                                                return -1;
                                                            } else {
                                                                const timeA = dateA.getTime();
                                                                const timeB = dateB.getTime();
                                                                return timeB - timeA;
                                                            }
                                                        })
                                                            .slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((statement, i) => (
                                                                <tr key={i}>
                                                                    <td>{statement?.voucherName}</td>

                                                                    <td>{statement?.voucherCode}</td>
                                                                    <td>{(statement?.voucherValue)}</td>
                                                                    <td>{getDate(statement?.createdDate, "DD-MM-YYYY")}</td>
                                                                    <td>{getDate(statement?.voucherExpiry, "DD-MM-YYYY")}</td>
                                                                    <td>
                                                                        {statement?.voucherRedeem == true ? "True" : "False"}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        : <tr>
                                                            <td colSpan="6">
                                                                <h6 className="text-center">No Record Found</h6>
                                                            </td>
                                                        </tr>}
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                    {matchedVoucherStatus && matchedVoucherStatus?.length > 0 && (
                                        <div>
                                            <div className="dashboard__paginationBox">
                                                <Page currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} value={5} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                         
                            <div className={`tab-pane ${activeTab === "giftcard" ? "active" : ""}`} id="giftcard" role="tabpanel" aria-labelledby="giftcard-tab">
                                {orderList?.length ? (
                                    <div className="giftcards_row">
                                        {orderList?.map((ele, ind) =>
                                            ele?.item_list.map((item) => (
                                                <div className={`giftcards_box ${index === ind && isActive === true ? "active" : ""}`} onClick={() => toggleActive(ind)} key={ind}>
                                                    <div className="giftcards_box_inner">
                                                        <div className="giftcards_ibox">
                                                            <img src={item?.image ? item?.image : giftCard} alt="gift card" />
                                                        </div>
                                                        <div className="giftcards_cbox">
                                                            <h4>{item?.name}</h4>
                                                            <p>
                                                                Quantity: <span>{item?.quantity}</span>
                                                            </p>
                                                            <ul>
                                                                <li>Pts: {ele?.total_points_redeemed}</li>
                                                                <li className="refunded">Order Placed</li>
                                                            </ul>
                                                            <small>Gift cards or vouchers once bought cannot be exchanged, refunded or cancelled.</small>
                                                        </div>
                                                    </div>
                                                    <div className="giftcards_obox">
                                                        <p>
                                                            Order ID: <strong>{ele?.code}</strong> Order On: <strong>{getDate(ele?.order_date, "YYYY-MM-DD hh:mm:ss")}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                ) : (
                                    <h6 className="text-center"> No Record Found</h6>
                                )}
                            </div>
                        </div>
                            )}
                    </div>
                    <div className="footer__Box">
                        <div className="inner_footer">
                            <div className="inner_footerbox inner_footerbox_1">
                                <h4>Quick Links</h4>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/chooseReward">Rewards</Link></li>
                                    <li><Link to="/customer-portal?tab=history">History</Link></li>
                                    <li><Link to="/customer-portal?tab=voucher">Vouchers</Link></li>
                                    <li><Link to="/customer-portal?tab=giftcard">Gift Cards</Link></li>
                                </ul>
                            </div>
                            <div className="inner_footerbox inner_footerbox_2">
                                <h4>Our Location</h4>
                                <p>Maritime Centre <br />29 Tenth Avenue <br />Barataria, <br />Trinidad, West Indies</p>
                            </div>
                            <div className="inner_footerbox inner_footerbox_3">
                                <h4>Contact Us</h4>
                                <ul>
                                    <li><a href="tel:+1868-607-6274">+1868-607-6274</a></li>
                                    <li><a href="tel:+1868-674-0130">+1868-674-0130</a></li>
                                    <li><a href="mailto:email@maritimefinancial.com">email@maritimefinancial.com</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="copyright_box">
                            <div className="copyright_innerbox">
                                <div className="copyright_textbox">
                                    <ul>
                                        <li>© 2023 The Maritime Financial Group. All Rights Reserved</li>
                                        <li><a href="https://maritimefinancial.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                                    </ul>
                                </div>
                                <div className="copyright_arrowbox">
                                    <a href="#" id="totopbutton"><span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    );
};

export default CustomerPortal;
